@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baumans&display=swap');

.piechart {
  svg g {
    /* Styles for the second <g> tag */
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
  }
}

:root {
  --primary-color: '#1B9FDA';
  --primary-grey: '#7D7D7D';
}

/* GLOBAL-STYLE */
* {
  font-family: 'Rubik', sans-serif;
}

.font-baumans {
  font-family: 'Baumans', system-ui;
  font-weight: 400;
  font-style: normal;
}

.font-montserrat {
  font-family: 'Montserrat', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.font-poppins {
  font-family: 'Poppins', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

button {
  text-align: left;
}

.small-scroll::-webkit-scrollbar {
  width: 0.4em;
  height: 0.4em;
}

.small-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.small-scroll::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 50px;
}

.small-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #c6c6c6;
}

.small-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.imageSlider .slick-track {
  display: flex;
}

.authSlider {
  bottom: 20px !important;
  position: relative;
}

.authSlider .slick-track {
  display: flex;
}

.authSlider .slick-dots {
  display: flex !important;
  justify-content: center;
  padding: 20px 0;
  position: relative;
  bottom: 4.5rem;
}

.authSlider .slick-dots li {
  list-style: none;
  margin: 0 5px;
}

.authSlider .slick-dots button {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
}

.authSlider .slick-dots button:before {
  content: '' !important;
  display: block;
  width: 50px !important;
  height: 3px !important;
  background-color: white;
  opacity: 1 !important;
  border-radius: 1px;
  transition: all 0.3s ease;
  opacity: 0.5;
}

.authSlider .slick-dots .slick-active button:before {
  opacity: 1 !important;
}

/* .profile tr th {

} */

.table-header tbody td {
  padding-bottom: 5px;
  padding-right: 10px;
  /* background-color: aqua; */
}

/* styles.css */
.custom-switch .nextui-switch-track {
  background-color: #d5dbe0;
}

.custom-switch .nextui-switch-thumb {
  background-color: white;
}

.custom-switch input:checked + .nextui-switch-track {
  background-color: green;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 6px;
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #50505060;
  border-radius: 10px;
}

.thin-scrollbar::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.group[data-selected='true'] .group-data-[selected='true'] {
  background-color: 'green';
  /* Assuming bg-primary sets background color */
}

.react-toggle--checked .react-toggle-track {
  background-color: #5bac0a !important;
}

.react-toggle-track {
  background-color: #d5dbe0 !important;
}

.videoBox iframe {
  border-radius: 14px;
}

.bg-svg {
  background-image: url('data:image/svg+xml,%3Csvg  viewBox="0 0 236 177" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M204.343 176.718V29.8679H133.863V176.718H102.206V29.8679H86.2078C67.9747 29.8679 55.6506 31.5191 49.5845 34.7695C37.6794 41.0457 31.6482 54.0821 31.6482 73.5329V176.726H0V73.5329C0 58.8367 1.69326 46.051 5.01868 35.5303C8.32664 25.1046 15.3528 16.4857 25.8964 9.91562C36.2392 3.32826 50.3526 0 67.87 0H236V176.718H204.343Z" fill="white"/%3E%3C/svg%3E');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.5;
  /* Set the desired opacity */
}

/* Homepage Accordion */
.gradient-text {
  transition: all 0.3s ease-in-out;
}

.gradient-texts {
  transition: all 0.1s ease-in-out;
}

.mainGradient:hover > .gradient-text {
  background: linear-gradient(to bottom, #1b9fda, white);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.mainGradient:hover > .gradient-texts {
  color: #1b9fda;
}

.mainGradient:hover > .gradient-img {
  filter: saturate(1);
  color: transparent;
}

.active-gradient-text {
  background: linear-gradient(to bottom, #1b9fda, white);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.telegram-bg {
  background-color: rgba(41, 169, 234, 0.1);
}

.linkedin-bg {
  background-color: rgba(0, 122, 185, 0.1);
}

.twitter-bg {
  background-color: rgba(0, 0, 0, 0.1);
}

.facebook-bg {
  background-color: rgba(24, 119, 242, 0.1);
}

.youtube-bg {
  background-color: rgba(255, 0, 0, 0.1);
}

.instagram-bg {
  background-color: rgba(196, 28, 154, 0.1);
}

.share-bg {
  background-color: rgba(0, 0, 0, 0.1);
}

.externalApps .slick-track {
  display: flex;
  align-items: center;
}

.adsSwiper {
  transition: all;
}

.adsSwiper .swiper-slide {
  opacity: 0.6;
}

.adsSwiper .swiper-slide-active {
  opacity: 1;
}

.PhoneInputInput {
  outline: none;
  border: none;
}

.text-lg svg g text {
  font-size: 16px !important;
}

.tab-custom span {
  box-shadow: 0 2px 0px 0 #1b9fda;
}

.list-decimal {
  list-style-type: decimal;
  margin-left: 20px;
  /* Optional: Ensure there's enough space for the numbers to display */
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  display: none;
}

.AllWalletSlider .swiper-button-prev.swiper-button-disabled,
.AllWalletSlider .swiper-button-next.swiper-button-disabled {
  display: none;
}

/* Hide arrows for Firefox */
.no-arrows[type='number'] {
  -moz-appearance: textfield;
}

input[type='range'] {
  --track-color: #1b9fda;
  /* Custom color for the track */
  --thumb-color: #ffffff;
  /* Custom color for the thumb */
}

input[type='range']::-webkit-slider-thumb {
  appearance: none;
  width: 16px;
  height: 16px;
  background: white;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  border: 1px solid var(--track-color);
}

/* Thumb styling for Firefox */
input[type='range']::-moz-range-thumb {
  appearance: none;
  /* Remove default thumb styling */
  width: 14px;
  /* Width of the thumb */
  height: 14px;
  /* Height of the thumb */
  /* background: white; */
  /* Background color */
  border-radius: 50%;
  /* Make it circular */
  top: 0;
  cursor: pointer;
  /* Pointer cursor on hover */
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);

  /* Shadow */
  /* border: 1px solid var(--track-color); */
  /* Border color */
}

/* Thumb styling for Firefox */
input[type='range']::-moz-range-thumb {
  appearance: none;
  /* Remove default thumb styling */
  width: 14px;
  /* Width of the thumb */
  height: 14px;
  /* Height of the thumb */
  /* background: white; */
  /* Background color */
  border-radius: 50%;
  /* Make it circular */
  top: 0;
  cursor: pointer;
  /* Pointer cursor on hover */
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);

  /* Shadow */
  /* border: 1px solid var(--track-color); */
  /* Border color */
}

.nextui-modal {
  z-index: 99999 !important;
}

.card-key-title {
  @apply font-[Rubik] md:text-sm text-sm font-normal leading-[16.59px] text-primaryGrey;
}

.card-value-title {
  @apply font-[Rubik] text-sm font-medium leading-[23.7px] text-primaryDark;
}

/* index.css or a specific CSS file */
.superNodeSwitch .react-toggle-track {
  background-color: #d1d5db;
  /* Tailwind's gray-300 equivalent */
  width: 42px;
  /* Customize size */
  height: 19px !important;
  /* Customize size */
  border-radius: 9999px;
  /* Full rounded corners */
}

.superNodeSwitch .react-toggle--checked .react-toggle-track {
  background-color: #76ee59 !important;
  /* Custom active color */
}

.superNodeSwitch .react-toggle-thumb {
  width: 18px !important;
  height: 18px !important;
  border-radius: 9999px;
}

.walletSlider {
  position: relative; /* Ensure the slider container is positioned relative */
}

.walletSlider .swiper-button-prev-custom,
.walletSlider .swiper-button-next-custom {
  position: absolute;
  top: 10px; /* Adjust top as needed */
  right: 10px; /* Align both buttons to the right with some padding */
  opacity: 1;
  pointer-events: auto;
  /* background-color: black; */
  cursor: pointer;
  color: #969696;
  padding: 10px;
  border-radius: 50%;
  font-size: 20px;
  z-index: 10; /* Ensure the buttons are above other elements */
}

.walletSlider .swiper-button-prev-custom {
  transform: translateX(
    -120%
  ); /* Adjust the offset to place the prev button to the left */
}

.walletSlider .swiper-button-next-custom {
  transform: translateX(0); /* The next button stays in the right corner */
}

.walletSlider .swiper-button-prev-custom.swiper-button-disabled,
.walletSlider .swiper-button-next-custom.swiper-button-disabled {
  opacity: 1; /* Keep buttons visible even when "disabled" */
  pointer-events: auto;
  cursor: pointer;
}

.bg-gradient-primary-red {
  background: linear-gradient(to right, #ef4444, #f15b5b, #f58a8a, #f15b5b);
}

.LatestTransactionSlider .swiper-button-prev,
.LatestTransactionSlider .swiper-button-next {
  width: 40px; /* Adjust size */
  height: 40px; /* Adjust size */
  background-color: #fff; /* Circle background color */
  border-radius: 50%; /* Make it circular */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  color: #000; /* Arrow color */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Optional shadow */
  transition: background-color 0.3s; /* Smooth transition without scaling */
  opacity: 0.35;
}

.LatestTransactionSlider .swiper-button-prev:hover,
.LatestTransactionSlider .swiper-button-next:hover {
  background-color: #fff; /* Hover background color */
  /* Removed transform: scale(1.1); to prevent position shift */
  opacity: 1;
}

.LatestTransactionSlider .swiper-button-prev {
  left: 20px !important;
}

.LatestTransactionSlider .swiper-button-next {
  right: 20px !important;
}

/* Center the navigation arrows vertically and horizontally */
.LatestTransactionSlider .swiper-button-prev,
.LatestTransactionSlider .swiper-button-next {
  top: 50% !important; /* Center vertically */
  transform: translateY(-50%);
}

/* Media query for small screens (e.g., below 768px width) */
@media (max-width: 767px) {
  .LatestTransactionSlider .swiper-button-prev {
    left: 0px !important;
  }

  .LatestTransactionSlider .swiper-button-next {
    right: 0px !important;
  }
}

/* .LatestTransactionSlider {
  position: relative;
}

.LatestTransactionSlider .swiper-button-prev-custom,
.LatestTransactionSlider .swiper-button-next-custom {
  position: absolute;
  top: 10px;
  right: 10px; 
  opacity: 1;
  pointer-events: auto;
  
  cursor: pointer;
  color:  #969696;
  padding: 10px;
  border-radius: 50%;
  font-size: 20px;
  z-index: 10;
}

.LatestTransactionSlider .swiper-button-prev-custom {
  transform: translateX(-120%); 
}

.LatestTransactionSlider .swiper-button-next-custom {
  transform: translateX(0); 
}

.LatestTransactionSlider .swiper-button-prev-custom.swiper-button-disabled,
.LatestTransactionSlider .swiper-button-next-custom.swiper-button-disabled {
  opacity: 1; 
  pointer-events: auto;
  cursor: pointer;
} */
/* .walletSlider .swiper-slide {
    transition: transform 0.3s ease-in-out;
  }

  .walletSlider .focus-scale {
    transform: scale(1.1) !important;
    z-index: 10; 
  } */

.bg-grid-pattern {
  background-image: linear-gradient(#e6e6e6 1px, transparent 1px),
    linear-gradient(90deg, #e6e6e6 1px, transparent 1px);
  background-size: 40px 60px; /* Adjust the size of the grid */
  border-radius: 20px;
}

.transaction-swiper-pagination-custom .swiper-pagination-bullet {
  width: 14px;
  height: 14px;
  background-color: transparent;
  border: 1px solid #d9e4f1; /* Outer circle border color */
  border-radius: 50%;
  position: relative;
  margin: 0 5px;
  opacity: 0.5;
  transition: opacity 0.3s;
}

.transaction-swiper-pagination-custom .swiper-pagination-bullet::after {
  content: '';
  width: 7px;
  height: 7px;
  background-color: #d9e4f1; /* Inner circle color */
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: background-color 0.3s;
}

.transaction-swiper-pagination-custom .swiper-pagination-bullet-active {
  opacity: 1;
}

.transaction-swiper-pagination-custom .swiper-pagination-bullet-active::after {
  background-color: #1b9fda; /* Change active inner circle color */
}

.react-toggle--checked .react-toggle-thumb {
  margin-top: 0.5px;
  border-color: white !important;
  background: linear-gradient(180deg, #ffffff 0%, #e8eaea 100%) !important;
  box-shadow: -2px 1px 6px 0px #00000040 !important;
  left: 15px !important;
}

.react-toggle-thumb {
  border: none !important;
  box-shadow: none !important;
  width: 13px !important;
  height: 13px !important;
  margin-top: 0.5px;
}

.reset-pass-switch-checked > .react-toggle--checked .react-toggle-thumb {
  margin-top: 0.5px;
  border-color: white !important;
  background: linear-gradient(180deg, #ffffff 0%, #e8eaea 100%) !important;
  box-shadow: -2px 1px 6px 0px #00000040 !important;
  left: 16px !important;
}

.reset-pass-switch > .react-toggle-thumb {
  border: none !important;
  box-shadow: none !important;
  width: 13px !important;
  height: 13px !important;
  margin-top: 0.5px;
}

.reset-pass-switch > .react-toggle-track {
  width: 31px;
  height: 16px;
  padding: 0;
  border-radius: 30px;
  background-color: #4d4d4d;
  transition: all 0.2s ease;
}

.superNodeSwitch .react-toggle--checked .react-toggle-thumb {
  left: 22px;
  border-color: white !important;
}

.AllWalletSlider .swiper-button-prev,
.AllWalletSlider .swiper-button-next {
  width: 40px; /* Adjust size */
  height: 40px; /* Adjust size */
  background: #ffffff;
  border-radius: 5px;
  /* Make it circular */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  color: #969696 !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Optional shadow */
  transition: background-color 0.3s; /* Smooth transition without scaling */
}

.AllWalletSlider .swiper-button-prev:hover,
.AllWalletSlider .swiper-button-next:hover {
  background-color: #f0f0f0; /* Hover background color */
  /* Removed transform: scale(1.1); to prevent position shift */
}

.AllWalletSlider .swiper-button-prev {
  left: 10px !important;
}

.AllWalletSlider .swiper-button-next {
  right: 10px !important;
}

/* Center the navigation arrows vertically and horizontally */
.AllWalletSlider .swiper-button-prev,
.AllWalletSlider .swiper-button-next {
  top: 50% !important; /* Center vertically */
  transform: translateY(-50%);
}

.AllWalletSlider .swiper-button-next::after {
  /* content: '›';  */
  font-size: 16px !important;
  color: #969696 !important;
  /* font-family: sans-serif !important; */
  font-weight: medium !important;
}

.AllWalletSlider .swiper-button-prev::after {
  /* content: '‹';  */
  font-size: 16px !important;
  color: #969696 !important;
  /* font-family: sans-serif !important; */
  font-weight: medium !important;
}

/* Media query for small screens (e.g., below 768px width) */
.walletSlider .swiper-wrapper {
  min-height: 380px;
  padding: 0;
}

.AllWalletSlider .swiper-button-prev {
  left: 5px !important;
}

.AllWalletSlider .swiper-button-next {
  right: 5px !important;
}

@media (max-width: 640px) {
  .AllWalletSlider .swiper-button-prev,
  .AllWalletSlider .swiper-button-next {
    width: 28px; /* Adjust size */
    height: 28px; /* Adjust size */
    background: #ffffff;
    border-radius: 5px; /* Make it circular */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    color: #969696 !important;

    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Optional shadow */
    transition: background-color 0.3s; /* Smooth transition without scaling */
    font-weight: normal;
  }

  .AllWalletSlider .swiper-button-prev {
    left: 5px !important;
  }

  .AllWalletSlider .swiper-button-next {
    right: 5px !important;
  }

  .AllWalletSlider .swiper-button-next::after,
  .AllWalletSlider .swiper-button-prev::after {
    font-size: 12px !important;
    font-weight: medium !important;
  }
}

.walletSlider .swiper-wrapper {
  display: flex;
}

.walletSlider .swiper-slide {
  display: flex;
  justify-content: center;
}

[role='tooltip'] {
  position: static !important;
}

.walletPieChart svg {
  overflow: visible;
}

@media (min-width: 640px) {
  .walletSlider .swiper-wrapper {
    padding: 0 10px;
  }
}

@media (min-width: 1280px) {
  .walletSlider .swiper-wrapper {
    justify-content: unset;
  }
}

/* @media (min-width: 1975px) {
    .walletSlider .swiper-wrapper {
        justify-content: center;
    }
} */

.AllWalletSlider .swiper {
  position: static;
}

.increaseSize {
  backface-visibility: hidden;
  transform: translateZ(0);
  -webkit-font-smoothing: subpixel-antialiased;
}

.scale-transform {
  will-change: transform;
  transform: scale(1);
  transition: all 0.3s ease-in-out;
}

.scale-down {
  will-change: transform;
  transform: scale(0.9);
  transition: all 0.3s ease-in-out;
}

.animationCls {
  transition: all 0.3s ease-in-out;
}

.nodek-cardHeader-clip {
  clip-path: polygon(0 0, calc(100% - 50px) 0, 100% 100%, 0% 130%);
  position: relative;
  z-index: 1;
  background-color: #d9e4f1;
  min-width: 250px;
  padding-right: 50px;
}

@media (max-width: 767px) {
  .nodek-cardHeader-clip {
    min-width: 60%;
    padding-right: 20px;
  }
}

.nodek-cardHeader-clip::after {
  content: '';
  position: absolute;
  width: calc(100% + -1px);
  height: calc(100% + 0px);
  background-color: white;
  z-index: 1;
  clip-path: polygon(0 0, calc(100% - 50px) 0, 100% 100%, 0% 100%);
  left: 1px;
  top: 1px;
  border-radius: 0.5rem 0 0 0;
}

@keyframes clippath {
  0%,
  100% {
    clip-path: inset(0 0 98% 0);
  }

  25% {
    clip-path: inset(0 98% 0 0);
  }

  50% {
    clip-path: inset(98% 0 0 0);
  }

  75% {
    clip-path: inset(0 0 0 98%);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.cardHoverEffect {
  position: relative;
  z-index: 0;
}

.cardHoverEffect::after {
  animation: clippath 3s linear -1.5s infinite !important;
}

.cardHoverEffect::after,
.cardHoverEffect::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border: 1px solid #1b9fda;
  transition: all 0.5s;
  animation: clippath 3s linear infinite;
  opacity: 0;
  z-index: -1;
}

.radius-22px::after,
.radius-22px::before {
  border-radius: 22px;
}

.radius-12px::after,
.radius-12px::before {
  border-radius: 12px;
}

.radius-5px::after,
.radius-5px::before {
  border-radius: 7px;
}

.cardHoverEffect:hover::after,
.cardHoverEffect:hover::before {
  opacity: 1;
}

.fadein {
  animation: fadein 0.15s linear;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.countryBorderGradient {
  border-color: linear-gradient(137.5deg, #1b9fda 0.49%, #ffffff 47.32%);
  border: 4px solid;
  border-radius: 12px; /* Optional */
  padding: 2px; /* Space between gradient border and content */
}

.voucher-dashed-border {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%23333' stroke-width='2' stroke-dasharray='7' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 2px;
}

.animate-pendulum {
  animation: pendulum 5s ease-in-out infinite;
  transform-origin: top center;
}

.animate-pendulum2 {
  animation: pendulum2 5s ease-in-out infinite;
  transform-origin: top center;
}

@keyframes pendulum {
  0% {
    transform: rotate(-15deg);
  }
  50% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(-15deg);
  }
}

@keyframes pendulum2 {
  0% {
    transform: rotate(17deg);
  }
  50% {
    transform: rotate(-17deg);
  }
  100% {
    transform: rotate(17deg);
  }
}

.rfm-marquee,
.rfm-initial-child-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

/* Custom scrollbar styles */
.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 4px;
}

.leaders .rfm-marquee,
.rfm-initial-child-container {
  width: auto !important;
}

.skew-menu {
  width: 100%;
}

.skew-menu ul {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;
  transform: skew(-18deg); /* Adjust skew angle as needed */
}

.skew-menu ul li {
  background: #fff;
  float: left;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  color: #555;
  transition: all 0.3s linear;
  /* border: 1px solid #000; */
  /* border-bottom: 1px solid #000; */
  border-radius: 10px 10px 10px 10px;
}

/* .skew-menu ul li:first-child {
  border-radius: 10px 0 0 10px;
  border-left: 1px solid #000;
}

.skew-menu ul li:last-child {
  border-radius: 0 10px 10px 0;
  border-right: 1px solid #000;
} */

.skew-menu ul li .skew-inner {
  color: inherit;
  transform: skew(18deg); /* Adjust skew angle as needed */
}

input[type='search']::-webkit-search-cancel-button {
  cursor: pointer;
}
input[type='search']::-moz-search-cancel-button {
  cursor: pointer;
}
input[type='search']::-ms-clear {
  cursor: pointer;
}
