/* Custom styles for the next button */
.swiper-button-next {
  color: #0e173f; /* Change the arrow color */
  background-color: transparent; /* Add background color */
  border-radius: 50%; /* Make the button round */
  width: 8px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  top: 150px !important;
  right: -5px !important;
  z-index: 10 !important;
}

/* Custom styles for the previous button */
.swiper-button-prev {
  color: #0e173f; /* Change the arrow color */
  background-color: transparent; /* Add background color */
  border-radius: 50%; /* Make the button round */
  width: 8px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  top: 150px !important;
  left: -5px !important;
  z-index: 10 !important;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 18px !important;
  font-weight: bolder !important;
  color: #0e173f !important;
}

/* Additional styles to adjust the position if needed */
.swiper-button-next,
.swiper-button-prev {
  top: 50%; /* Vertically center the buttons */
  transform: translateY(-50%);
  width: 8px;
  height: 12px;
}
.swiper-pagination {
  position: relative !important;
  margin-top: 15px !important;
}

.swiper-pagination-bullet {
  background: grey !important;
  border: 3px solid white !important;
  box-shadow: 0px 0px 2px grey !important;
  width: 15px !important;
  height: 15px !important;
}

.swiper-pagination-bullet-active {
  background: #1b9fda !important;
  border: 3px solid white !important;
  box-shadow: 0px 0px 2px grey !important;
  width: 15px !important;
  height: 15px !important;
}

@media screen and (max-width: 425px) {
  .swiper-button-next {
    right: -10px !important;
  }
  .swiper-button-prev {
    left: -10px !important;
  }
}

.cloudkSlider .swiper-button-next {
  top: 60% !important;
  transform: translateY(-50%);
  /* width: 8px;
  height: 12px; */
  z-index: 999;
  right: 10px !important;
}

.cloudkSlider .swiper-button-prev {
  top: 60% !important;
  transform: translateY(-50%);
  /* width: 8px;
  height: 12px; */
  z-index: 999;
  left: 10px !important;
}

.topMachineSlider .swiper-button-next::after {
  color: #000000 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.topMachineSlider .swiper-button-prev::after {
  color: #000000 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.mobile-connect-swiper-pagination-custom .swiper-pagination-bullet {
  margin: 20px 0px;
  border: 1px solid black !important;
  width: 7px !important;
  height: 7px !important;
  background-color: #ffff !important;
}

.mobile-connect-swiper-pagination-custom .swiper-pagination-bullet-active {
  background: #1b9fda !important;
  border: 0px solid white !important;
  box-shadow: 0px 0px 2px grey !important;
}
